@import "../../../../assets/scss/theme.scss";

.device-details {
  .field-title {
    font-size: 1rem;
    color: $gray-500;
  }
  .field-value {
    font-size: 1rem;
    font-weight: 500;
  }
  .btn.btn-outline-secondary {
    min-width: 250px;
  }
}
.device-details-metrics {
  .total-count {
    font-size: 3rem;
    font-weight: 500;
    line-height: 3rem;
  }
  .total-count-descr {
    color: $gray-500;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 30px;
    white-space: nowrap;
  }

  .diagnostics-box {
    .total-count {
      font-size: 2rem;
      line-height: 2rem;
    }
    .total-count-descr {
      font-size: .9rem;
      font-weight: 200;
      margin-bottom: 20px;
    }
  }
}
